// // File: src/components/About/About.tsx

// import { Container } from "./styles";
// import TobyMessier from "../../assets/toby-round.png";
// import wordpress from "../../assets/wordpress.svg";
// import shopify from "../../assets/shopify.svg";
// import htmlIcon from "../../assets/html-icon.svg";
// import cssIcon from "../../assets/css-icon.svg";
// import jsIcon from "../../assets/js-icon.svg";
// import nodeIcon from "../../assets/node-icon.svg";
// import reactIcon from "../../assets/react-icon.svg";
// import typescriptIcon from "../../assets/typescript-icon.svg";
// import vueIcon from "../../assets/vue-icon.svg";
// import boostrapIcon from "../../assets/bootstrap-icon.svg";

// import victoryIcon from "../../assets/victory-medal-svgrepo-com.svg";

// import ScrollAnimation from "react-animate-on-scroll";

// export function About() {
//   return (
//     <Container id="about">
//       <div className="about-text">


//       <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
//           <h3>Metrics:</h3>
//         </ScrollAnimation>
//         <div className="hard-skills">
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.10 * 1000}>
//               <img src={wordpress} alt="Wordpress" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.12 * 1000}>
//               <img src={shopify} alt="shopify" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
//               <img src={reactIcon} alt="React" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
//               <img src={typescriptIcon} alt="Typescript" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
//               <img src={victoryIcon} alt="Typescript" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.15 * 1000}>
//               <img src={vueIcon} alt="Vue" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.16 * 1000}>
//               <img src={nodeIcon} alt="Node" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.17 * 1000}>
//               <img src={htmlIcon} alt="Html" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.18 * 1000}>
//               <img src={cssIcon} alt="Css" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
//               <img src={boostrapIcon} alt="bootstrap" />
//             </ScrollAnimation>
//           </div>
//           <div className="hability">
//             <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
//               <img src={jsIcon} alt="JavaScript" />
//             </ScrollAnimation>    
//           </div>       
//         </div>


//         <ScrollAnimation animateIn="fadeInLeft">
//           <h2>About me</h2>
//         </ScrollAnimation>
//         <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
//           <p>

//             I contribute both on the software, business strategy, sales and alliances.
//             I like b2b and b2b2c strategies. 
//             And I'm interested in a variety of industries. 
//             I love building the software myself, though I don't always, depending on the value added. 
//             I also love building strategic partnerships, whether it be for brand alliances, redistributions, revenue splits and much more.

//           </p>
//         </ScrollAnimation>
//         <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
//           <p>

//             I've worked in the financial industry, in consulting, as a medical doctor, 
//             in sales, as a software developer, in sustainability/climate tech, 
//             and as an executive as well as a startup founder.
            
//           </p>
//         </ScrollAnimation>
//         <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
//           <p>

//             I love learning new spaces. 
//             And applying the knowledge I've acquired to solve challenging problems in new ways 
//             and find new opportunities for existing businesses.
//             I am restless and strategic.

            
//           </p>
        
//         </ScrollAnimation>



//       </div>
//       <div className="about-image">
//         <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
//           <img src={TobyMessier} alt="Toby Messier" />
//         </ScrollAnimation>
//       </div>
//     </Container>
//   )
// }

import { Container } from "./styles";
import TobyMessier from "../../assets/toby-round.png";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  const metrics = [
    { label: "Financial Risks Identified", value: "$500B+" },
    { label: "Raised from External Investors", value: "$100K" },
    { label: "Total Exit Value", value: "$20M+" },
    { label: "Medical Doctors Trained", value: "600+" },
    { label: "AI Platforms Developed", value: "4" },
    { label: "Global Partnerships Signed", value: "25+" },
    { label: "Multi-day Conferences Hosted", value: "7" },
    { label: "Doctoral Degrees Completed", value: "1" },
    { label: "Largest Retail Investor in Public Companies", value: "1" }
  ];

  return (
    <Container id="about">
      <div className="about-text">

        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Metrics</h2>
        </ScrollAnimation>


        <div className="metrics-grid">
          {metrics.map((metric, index) => (
            <ScrollAnimation key={index} animateIn="fadeInUp" delay={(index + 1) * 0.1 * 1000}>
              <div className="metric-card">
                <h4>{metric.value}</h4>
                <p>{metric.label}</p>
              </div>
            </ScrollAnimation>
          ))}
        </div>


        <ScrollAnimation animateIn="fadeInLeft" delay={0.00 * 1000}>
          <h3>Story</h3>
        </ScrollAnimation>


        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          
          My entrepreneurial journey began in high school, where a “hacker” mindset led me to explore technology through both software and hardware, pushing boundaries and learning by doing. From the start, I aimed not to follow systems but to build them.
          
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>

            At 18, I pursued a medical doctorate, completing the degree but stepping away from residency to create solutions with greater impact. Though unconventional after three early business failures, this shift aligned fully with my ambitions.
          
          </p>
        </ScrollAnimation>
        {/* <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>

            I’ve since had the privilege of working across industries, holding roles in finance, consulting, sales, software development, sustainability/climate tech, and as an executive and founder. Each experience has added depth to my approach and strengthened my cross-disciplinary insights.
          
          </p>
        </ScrollAnimation> */}
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <p>

            Today, I thrive in system-building that bridges industries, energized by learning and hands-on engineering, whether building myself or leading a skilled team.
          
          </p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={TobyMessier} alt="Toby Messier" />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
