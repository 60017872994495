import { BrowserRouter } from "react-router-dom"
import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"
import Illustration from "../../assets/illustration.svg"
import { NavHashLink } from "react-router-hash-link"
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import whatsapp from '../../assets/whatsapp.svg'
import Hello from '../../assets/Hello.gif'
import telegram from '../../assets/telegram.svg'

import img1 from '../../assets/oldsport-round.png';
import img2 from '../../assets/ihm-round.png';
import img3 from '../../assets/produ-round.png';
import img4 from '../../assets/omega-round.png';
import img5 from '../../assets/aqx-round.png';
import img6 from '../../assets/seven-round.png';
// import img7 from '../../assets/viralblitz-round.png';
import img7 from '../../assets/vb-round.png';
import img8 from '../../assets/caplogiq-round.png';
import img9 from '../../assets/sympto-round.png';
import img10 from '../../assets/cognifyfx-round.png';



export function Hero() {

  // const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
  const images = [
    { src: img1, status: "Failed" },
    { src: img2, status: "Failed" },
    { src: img3, status: "Failed" },
    { src: img4, status: "Exited" },
    { src: img5, status: "Exited" },
    { src: img6, status: "Active" },
    { src: img7, status: "Active" },
    { src: img8, status: "Active" },
    { src: img9, status: "Pivot" },
    { src: img10, status: "Active" }
  ];

  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
          <p>Hello <img src={Hello} alt="Hello" width="20px"/>, I'm a</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Cross-Industry Builder</h1>
          {/* <div className="custom-title">Cross-Industry Builder</div> */}

        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>My first three businesses failed. Then, I dropped out of school.</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">Now, I build transformative businesses with passionate people.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to="#about" className="button">Metrics</NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
      <div className="social-media"><a
        href="https://www.linkedin.com/in/toby-messier"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="Linkedin" />
      </a>
        <a
          href="https://github.com/TobyMessier/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>
        {/* <a
          href="https://api.whatsapp.com/send/?phone=%2B919630576848&text=Hello+Vinayak"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" />
        </a>
        <a
          href="https://t.me/CodeVinayak"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" />
        </a> */}
        </div>
        </ScrollAnimation>
      </div>
      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>

          {/* <img src={Illustration} alt="Ilustração" /> */}

          {/* <div className="image-grid">
            {images.map((src, index) => (
              <img key={index} src={src} alt={`img-${index + 1}`} />
            ))}
          </div> */}

          <div className="image-grid">
            {images.map((image, index) => (
              <div key={index} className="image-container">
                <div className={`banner ${image.status.toLowerCase()}`}>{image.status}</div>
                <img src={image.src} alt={`img-${index + 1}`} />
              </div>
            ))}
          </div>

        </ScrollAnimation>
      </div>
    </Container>
  )
}