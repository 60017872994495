import styled from "styled-components";

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0,0,0,0);
  .hero-text{
    & > p{
      font-size: 1.8rem;
    }
    h1{
      font-size: 7rem;
    }

    h3{
      color:var(--green);
      margin: 1rem 0;
    }

    
    p.small-resume {
      margin-bottom: 5rem;
    }
  }
// New added
  .social-media{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top:5rem;
    padding-left:1rem;

    img,span{
      font-size: 3rem;
      width: 3.5rem;
    }
  }


  .button{
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  .custom-title {
    font-size: 2.5em; /* Adjust size as needed */
    font-weight: bold; /* Optional: make it bold */
    margin: 0.5em 0; /* Optional: adjust spacing */
  }

  // .hero-image{
  //   img{
  //     max-width: 500px;
  //   }
  // }
  
  
  .hero-image .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px; /* Adjust gap between images as needed */
    column-gap: 10px; /* Adjusts only the distance between columns */
    max-width: 500px;
  }

  .hero-image .image-grid img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures images fit within grid cells */
    position: relative;
  }

  // added for status marker

  .hero-image .image-grid .image-container {
    position: relative;
    width: 100%;
  }

  .hero-image .image-grid .image-container img {
    width: 100%;
    height: auto;
  }

  .hero-image .image-grid .banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    width: 100%;
    // padding: 5px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    z-index: 1;
  }

  .banner.failed {
    background-color: rgba(255, 0, 0, 0.7); /* Red for Failed */
  }

  .banner.exited {
    background-color: rgba(0, 122, 255, 0.7); /* Blue for Exited */
  }

  .banner.active {
    background-color: rgba(0, 128, 0, 0.7); /* Green for Active */
  }

  .banner.pivot {
    background-color: rgba(255, 223, 0, 0.8); /* Yellow for Pivot */
  }


  @media(max-width: 960px){
    display: block;
    margin-top: 15%;
    .hero-text{

      h1{
        font-size: 5rem;
      }
    }
    
  .hero-image{
    display: none;
  }


  }

  @media(max-width: 600px){
    margin-top: 35%;
  }
  @media(max-width: 480px){
    margin-top: 45%;
  }
`