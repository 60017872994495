// // // // import { Container } from "./styles";
// // // // import githubIcon from "../../assets/github.svg"
// // // // // import DownloadApp from '../../assets/download.png'
// // // // import externalLink from "../../assets/external-link.svg"
// // // // import ScrollAnimation from "react-animate-on-scroll";


// // // // export function Project() {
// // // //   return (
// // // //     <Container id="project">
// // // //       <h2>My Businesses</h2>
// // // //       <div className="projects">

// // // //         <ScrollAnimation animateIn="flipInX">
// // // //           <div className="project">
// // // //             <header>
// // // //               <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
// // // //               <div className="project-links">
// // // //                 <a href="https://github.com/CodeVinayak/Serverless-Voting-Application" target="_blank" rel="noreferrer">
// // // //                   <img src={githubIcon} alt="Visit site" /></a>
// // // //                 <a href="https://vote.vinayaksingh.com" target="_blank" rel="noreferrer">
// // // //                   <img src={externalLink} alt="Visit site" />
// // // //                 </a> </div>
// // // //             </header>
// // // //             <div className="body">
// // // //               <h3>Serverless Voting Application for Programming Languages</h3>
// // // //               <p> Developed a serverless voting app using React, AWS Lambda, API Gateway, and DynamoDB, enabling users to view programming languages, cast votes, and access details through an interactive UI, leveraging serverless architecture for scalability and cost-efficiency. </p>
// // // //             </div>
// // // //             <footer> <ul className="tech-list"> <li>AWS Lambda</li> <li>API Gateway</li> <li>DynamoDB</li> </ul> </footer>
// // // //           </div>
// // // //         </ScrollAnimation>

// // // //         <ScrollAnimation animateIn="flipInX">
// // // //           <div className="project">
// // // //             <header>
// // // //               <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
// // // //               <div className="project-links">
// // // //                 <a href="https://github.com/CodeVinayak/AI-Chatbot-Assistant" target="_blank" rel="noreferrer">
// // // //                   <img src={githubIcon} alt="Visit site" /> </a>
// // // //                 <a href="https://ai-chatbot-t8fn.onrender.com" target="_blank" rel="noreferrer">
// // // //                   <img src={externalLink} alt="Visit site" /></a>
// // // //               </div>
// // // //             </header>
// // // //             <div className="body">
// // // //               <h3>GeniusBot: AI-Powered Assistance with PDF Insight</h3>
// // // //               <p>
// // // //                 Developed an interactive chatbot application using Streamlit, OpenAI's GPT-3.5-turbo language model, and PyPDF2 for PDF text extraction, enabling users to ask context-based questions on uploaded PDFs and general queries.
// // // //               </p>
// // // //             </div>
// // // //             <footer>
// // // //               <ul className="tech-list">
// // // //                 <li>Streamlit</li>
// // // //                 <li>PyPDF2</li>
// // // //                 <li>LangChain</li>
// // // //               </ul>
// // // //             </footer>
// // // //           </div>
// // // //         </ScrollAnimation>

// // // //         <ScrollAnimation animateIn="flipInX">
// // // //           <div className="project">
// // // //             <header>
// // // //               <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
// // // //                 <title>Folder</title>
// // // //                 <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
// // // //               </svg>
// // // //               <div className="project-links">
// // // //                 <a href="https://github.com/CodeVinayak/Jupyter-Notebook/tree/main/Credit%20Risk%20Analysis" target="\_blank" rel="noreferrer">
// // // //                   <img src={githubIcon} alt="Visit site" />
// // // //                 </a>
// // // //                 <a href="https://github.com/CodeVinayak/Jupyter-Notebook/blob/main/Credit%20Risk%20Analysis/Credit_Risk_Analysis.ipynb" target="\_blank" rel="noreferrer">
// // // //                   <img src={externalLink} alt="Visit site" />
// // // //                 </a>
// // // //               </div>
// // // //             </header>
// // // //             <div className="body">
// // // //               <h3>Credit Risk Analysis Project</h3>
// // // //               <p>
// // // //                 Developed a highly accurate credit risk classification model using XGBoost, achieving 100% precision, recall, and F1-scores for predicting loan defaults.Implemented rigorous data preprocessing, feature engineering, and hyperparameter tuning on imbalanced credit risk data, employing ensemble methods, cross-validation, and model interpretation techniques.
// // // //               </p>
// // // //             </div>
// // // //             <footer>
// // // //               <ul className="tech-list">
// // // //                 <li>XGBoost</li>
// // // //                 <li>Pandas</li>
// // // //                 <li>Matplotlib</li>
// // // //               </ul>
// // // //             </footer>
// // // //           </div>
// // // //         </ScrollAnimation>

// // // //         <ScrollAnimation animateIn="flipInX">
// // // //           <div className="project">
// // // //             <header>
// // // //               <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
// // // //               <div className="project-links">
// // // //                 <a href="https://github.com/CodeVinayak/MediBook-Medical-Appointment-Scheduler-with-Health-History" target="_blank" rel="noreferrer">
// // // //                   <img src={githubIcon} alt="Visit site" />
// // // //                 </a>
// // // //                 <a href="https://medibook.vinayaksingh.com" target="_blank" rel="noreferrer">
// // // //                   <img src={externalLink} alt="Visit site" />
// // // //                 </a>
// // // //               </div>
// // // //             </header>
// // // //             <div className="body">
// // // //               <h3>MediBook: Medical Appointment Scheduler with Health History</h3>
// // // //               <p>Developed a secure medical appointment management platform with CRUD functionality, utilizing role-based access control, encryption techniques, and a robust MySQL database with normalized schema design and stored procedures for efficient scheduling, data privacy, and reliable retrieval of patient records.</p>
// // // //             </div>
// // // //             <footer>
// // // //               <ul className="tech-list">
// // // //                 <li>PHP</li>
// // // //                 <li>HTML</li>
// // // //                 <li>CSS</li>
// // // //                 <li>MySQL</li>
// // // //               </ul>
// // // //             </footer>
// // // //           </div>
// // // //         </ScrollAnimation>

// // // //         <ScrollAnimation animateIn="flipInX">
// // // //           <div className="project">
// // // //             <header>
// // // //               <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
// // // //                 <title>Code</title>
// // // //                 <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
// // // //               </svg>
// // // //               <div className="project-links">
// // // //                 <a href="https://github.com/CodeVinayak/Real-Time-Hand-Gesture-Driven-3D-Object-Manipulation" target="_blank" rel="noreferrer">
// // // //                   <img src={githubIcon} alt="Visit site" />
// // // //                 </a>
// // // //                 <a href="https://youtu.be/NOm-3MynPLE" target="_blank" rel="noreferrer">
// // // //                   <img src={externalLink} alt="Visit site" />
// // // //                 </a>
// // // //               </div>
// // // //             </header>
// // // //             <div className="body">
// // // //               <h3>Real-Time Hand Gesture-Driven 3D Object Manipulation</h3>
// // // //               <p>Developed a real-time hand gesture recognition system for intuitive 3D object manipulation. Leveraged a multimodal approach integrating OpenCV for video processing, MediaPipe for hand detection/landmark extraction, and scikit-learn for machine learning model training. Achieved 95.2% accuracy in hand gesture classification with efficient real-time performance.</p>
// // // //             </div>
// // // //             <footer>
// // // //               <ul className="tech-list">
// // // //                 <li>OpenCV</li>
// // // //                 <li>MediaPipe</li>
// // // //                 <li>scikit-learn</li>
// // // //               </ul>
// // // //             </footer>
// // // //           </div>
// // // //         </ScrollAnimation>

// // // //         <ScrollAnimation animateIn="flipInX">
// // // //           <div className="project">
// // // //             <header>
// // // //               <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
// // // //               <div className="project-links">
// // // //                 <a href="https://velocityai.vinayaksingh.com/" target="_blank" rel="noreferrer">
// // // //                   <img src={externalLink} alt="Visit site" />
// // // //                 </a>
// // // //               </div>
// // // //             </header>
// // // //             <div className="body">
// // // //               <h3>VeloCityAI</h3>
// // // //               <p>
// // // //                 VelocityAI is a website that allows users to get instant answers to any question and generate stunning AI pictures using OpenAI's GPT-3 and DALL-E model.With VelocityAI, users can type in text-based prompts and receive a corresponding response.
// // // //               </p>
// // // //             </div>
// // // //             <footer>
// // // //               <ul className="tech-list">
// // // //                 <li>React</li>
// // // //                 <li>Typescript</li>
// // // //                 <li>Html</li>
// // // //                 <li>css</li>
// // // //               </ul>
// // // //             </footer>
// // // //           </div>
// // // //         </ScrollAnimation>

// // // //       </div>
// // // //     </Container>
// // // //   );
// // // // }

// // // // File: src/components/Project/Project.tsx

// // // import { Container } from "./styles";
// // // import ScrollAnimation from "react-animate-on-scroll";

// // // // Import project images and statuses
// // // import img1 from '../../assets/oldsport-round.png';
// // // import img2 from '../../assets/ihm-round.png';
// // // import img3 from '../../assets/produ-round.png';
// // // import img4 from '../../assets/omega-round.png';
// // // import img5 from '../../assets/aqx-round.png';
// // // import img6 from '../../assets/seven-round.png';
// // // import img7 from '../../assets/vb-round.png';
// // // import img8 from '../../assets/caplogiq-round.png';
// // // import img9 from '../../assets/sympto-round.png';
// // // import img10 from '../../assets/cognifyfx-round.png';

// // // const projects = [
// // //   { src: img1, name: "OldSport", status: "Failed", description: "Description of OldSport project." },
// // //   { src: img2, name: "IHM", status: "Failed", description: "Description of IHM project." },
// // //   { src: img3, name: "Productor", status: "Failed", description: "Description of Produ project." },
// // //   { src: img4, name: "Omega", status: "Exited", description: "Description of Omega project." },
// // //   { src: img5, name: "AQX", status: "Exited", description: "Description of AQX project." },
// // //   { src: img6, name: "SEVEN", status: "Active", description: "Description of SEVEN project." },
// // //   { src: img7, name: "Viral Blitz", status: "Active", description: "Description of Viral Blitz project." },
// // //   { src: img8, name: "Caplogiq", status: "Active", description: "Description of Caplogiq project." },
// // //   { src: img9, name: "SymptoTalk", status: "Pivot", description: "Description of SymptoTalk project." },
// // //   { src: img10, name: "CognifyFX", status: "Active", description: "Description of CognifyFX project." }
// // // ];

// // // export function Project() {
// // //   return (
// // //     <Container id="project">
// // //       <h2>My Businesses</h2>
// // //       <div className="projects">
// // //         {projects.map((project, index) => (
// // //           <ScrollAnimation key={index} animateIn="flipInX">
// // //             <div className="project">
// // //               <header>
// // //                 {/* Project image in the top left */}
// // //                 <img src={project.src} alt={`${project.name} logo`} className="project-image" />
                
// // //                 {/* Status Badge */}
// // //                 <span className={`status-badge ${project.status.toLowerCase()}`}>
// // //                   {project.status}
// // //                 </span>
// // //               </header>
              
// // //               <div className="body">
// // //                 <h3>{project.name}</h3>
// // //                 <p>{project.description}</p>
// // //               </div>
              
// // //               <footer>
// // //                 <ul className="tech-list">
// // //                   <li>Technology 1</li>
// // //                   <li>Technology 2</li>
// // //                   <li>Technology 3</li>
// // //                 </ul>
// // //               </footer>
// // //             </div>
// // //           </ScrollAnimation>
// // //         ))}
// // //       </div>
// // //     </Container>
// // //   );
// // // }


// // // File: src/components/Project/Project.tsx

// // import { Container } from "./styles";
// // import ScrollAnimation from "react-animate-on-scroll";

// // // Import project images and statuses
// // import img1 from '../../assets/oldsport-round.png';
// // import img2 from '../../assets/ihm-round.png';
// // import img3 from '../../assets/produ-round.png';
// // import img4 from '../../assets/omega-round.png';
// // import img5 from '../../assets/aqx-round.png';
// // import img6 from '../../assets/seven-round.png';
// // import img7 from '../../assets/vb-round.png';
// // import img8 from '../../assets/caplogiq-round.png';
// // import img9 from '../../assets/sympto-round.png';
// // import img10 from '../../assets/cognifyfx-round.png';

// // const projects = [
// //   { src: img1, name: "OldSport", status: "Failed", description: "A community-driven sports event platform.", labels: ["Sports", "Community", "Events"] },
// //   { src: img2, name: "IHM", status: "Failed", description: "Healthcare management solution.", labels: ["Healthcare", "Management", "B2B"] },
// //   { src: img3, name: "Productor", status: "Failed", description: "Marketplace for local producers.", labels: ["Marketplace", "Agriculture", "Local Business"] },
// //   { src: img4, name: "Omega", status: "Exited", description: "AI-driven predictive analytics.", labels: ["AI", "Predictive Analytics", "Finance"] },
// //   { src: img5, name: "AQX", status: "Exited", description: "Aquaculture management platform.", labels: ["Aquaculture", "Sustainability", "Data Management"] },
// //   { src: img6, name: "SEVEN", status: "Active", description: "Integrative wellness hub.", labels: ["Wellness", "Virtual Health", "Multidisciplinary"] },
// //   { src: img7, name: "Viral Blitz", status: "Active", description: "Social media marketing app for businesses.", labels: ["Social Media", "Marketing", "B2B"] },
// //   { src: img8, name: "Caplogiq", status: "Active", description: "Financial data analytics for capital management.", labels: ["Finance", "Data Analytics", "Capital Management"] },
// //   { src: img9, name: "SymptoTalk", status: "Pivot", description: "Telemedicine platform for symptom assessment.", labels: ["Telemedicine", "Symptom Assessment", "Healthcare"] },
// //   { src: img10, name: "CognifyFX", status: "Active", description: "Human capital performance improvement.", labels: ["Human Capital", "Performance", "Improvement"] }
// // ];

// // export function Project() {
// //   return (
// //     <Container id="project">
// //       <h2>My Businesses</h2>
// //       <div className="projects">
// //         {projects.map((project, index) => (
// //           <ScrollAnimation key={index} animateIn="flipInX">
// //             <div className="project">
// //               <header>
// //                 {/* Project image in the top left */}
// //                 <img src={project.src} alt={`${project.name} logo`} className="project-image" />
                
// //                 {/* Status Badge */}
// //                 <span className={`status-badge ${project.status.toLowerCase()}`}>
// //                   {project.status}
// //                 </span>
// //               </header>
              
// //               <div className="body">
// //                 <h3>{project.name}</h3>
// //                 <p>{project.description}</p>
// //               </div>
              
// //               <footer>
// //                 <ul className="tech-list">
// //                   {project.labels.map((label, i) => (
// //                     <li key={i}>{label}</li>
// //                   ))}
// //                 </ul>
// //               </footer>
// //             </div>
// //           </ScrollAnimation>
// //         ))}
// //       </div>
// //     </Container>
// //   );
// // }


// // File: src/components/Project/Project.tsx

// import { Container } from "./styles";
// import ScrollAnimation from "react-animate-on-scroll";

// // Import project images and statuses
// import img1 from '../../assets/oldsport-round.png';
// import img2 from '../../assets/ihm-round.png';
// import img3 from '../../assets/produ-round.png';
// import img4 from '../../assets/omega-round.png';
// import img5 from '../../assets/aqx-round.png';
// import img6 from '../../assets/seven-round.png';
// import img7 from '../../assets/vb-round.png';
// import img8 from '../../assets/caplogiq-round.png';
// import img9 from '../../assets/sympto-round.png';
// import img10 from '../../assets/cognifyfx-round.png';

// const projects = [
//   {
//     src: img1,
//     name: "OldSport",
//     status: "Failed",
//     question: "What if sponsoring amateur and minor sports leagues were effortless for advertisers?",
//     improvement: "I spent excessive time on operations and legal matters, diverting focus from product development and sales strategy.",
//     insight: "The specificity of ad targeting matters less than the volume of spend that a channel can support.",
//     labels: ["Sports", "Community", "Events"]
//   },
//   {
//     src: img2,
//     name: "IHM",
//     status: "Failed",
//     question: "What if modern psychometric tools transformed the management feedback process?",
//     improvement: "Over-invested in product development without sufficiently listening to the market and user needs. Prioritize an obsessive focus on customers.",
//     insight: "A product can be user-friendly and powerful, but if the organizational pain it addresses isn’t significant, it won’t drive purchasing decisions.",
//     labels: ["Healthcare", "Management", "B2B"]
//   },
//   {
//     src: img3,
//     name: "Productor",
//     status: "Failed",
//     question: "What if local producers had a streamlined platform to connect directly with buyers?",
//     improvement: "Focused too heavily on platform features rather than building buyer-seller relationships early on.",
//     insight: "Marketplaces thrive on trust and relationships; technology alone isn’t enough.",
//     labels: ["Marketplace", "Agriculture", "Local Business"]
//   },
//   // Continue adding other projects following the same format
// ];

// export function Project() {
//   return (
//     <Container id="project">
//       <h2>My Businesses</h2>
//       <div className="projects">
//         {projects.map((project, index) => (
//           <ScrollAnimation key={index} animateIn="flipInX">
//             <div className="project">
//               <header>
//                 {/* Project image in the top left */}
//                 <img src={project.src} alt={`${project.name} logo`} className="project-image" />
                
//                 {/* Status Badge */}
//                 <span className={`status-badge ${project.status.toLowerCase()}`}>
//                   {project.status}
//                 </span>
//               </header>
              
//               <div className="body">
//                 <h3>{project.name}</h3>
//                 <p className="question">{project.question}</p>
//                 <p className="improvement-title">Where I Could Have Improved:</p>
//                 <p className="improvement">{project.improvement}</p>
//                 <p className="insight-title">Key Insight:</p>
//                 <p className="insight">{project.insight}</p>
//               </div>
              
//               <footer>
//                 <ul className="tech-list">
//                   {project.labels.map((label, i) => (
//                     <li key={i}>{label}</li>
//                   ))}
//                 </ul>
//               </footer>
//             </div>
//           </ScrollAnimation>
//         ))}
//       </div>
//     </Container>
//   );
// }



// File: src/components/Project/Project.tsx

import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

// Import project images and statuses
import img1 from '../../assets/oldsport-round.png';
import img2 from '../../assets/ihm-round.png';
import img3 from '../../assets/produ-round.png';
import img4 from '../../assets/omega-round.png';
import img5 from '../../assets/aqx-round.png';
import img6 from '../../assets/seven-round.png';
import img7 from '../../assets/vb-round.png';
import img8 from '../../assets/caplogiq-round.png';
import img9 from '../../assets/sympto-round.png';
import img10 from '../../assets/cognifyfx-round.png';

const projects = [
  {
    src: img1,
    name: "OldSport",
    status: "Failed",
    shortDescription: "Enabling advertisers to engage with amateur and minor sports leagues.",
    improvement: "I focused too heavily on operations and legal aspects, which diverted crucial time and resources from product development and sales strategy.",
    insight: "The specificity of ad targeting is often less critical than the capacity for higher ad spend through a channel.",
    labels: ["Sports Marketing", "Ad Engagement", "Community Outreach"]
  },
  {
    src: img2,
    name: "IHM",
    status: "Failed",
    shortDescription: "Management feedback platform utilizing modern psychometrics.",
    improvement: "Excessive time was spent refining the product rather than focusing on market and user feedback. Prioritizing customer obsession is key.",
    insight: "Even a user-friendly and powerful product will struggle if the organizational pain it addresses isn’t pressing enough to drive purchases.",
    labels: ["Psychometrics", "Feedback", "Enterprise Solutions"]
  },
  {
    src: img3,
    name: "Productor",
    status: "Failed",
    shortDescription: "Manufacturing optimization in the Industry 4.0 landscape.",
    improvement: "An overly strong emphasis on sales and market engagement led to an underdeveloped product, resulting in a consulting-heavy model rather than a scalable software solution.",
    insight: "Enterprise clients may prefer paying for your expertise over your software. Avoid offering solutions where human experts perform as well or better than software, and establish engagement terms early.",
    labels: ["Manufacturing", "Optimization", "Industry 4.0"]
  },
  {
    src: img4,
    name: "OMEGA",
    status: "Exited",
    shortDescription: "Finance and management education tailored for doctors.",
    improvement: "A more aggressive scaling approach could have significantly expanded the business. The market and interest levels were ripe for it. Don’t hesitate to aim for substantial growth.",
    insight: "Mission-driven initiatives attract strong interest, but clarifying early on that you’re a for-profit business is essential to avoid being viewed as a public service or NGO.",
    labels: ["Education", "Finance", "Healthcare Professionals"]
  },
  {
    src: img5,
    name: "AQX",
    status: "Exited",
    shortDescription: "Empowering the financial sector to quantify climate change.",
    improvement: "Raising capital earlier, especially for R&D-intensive projects, would have accelerated progress. Bootstrapping cost valuable time, while competitors raised more than our exit value to develop a comparable product.",
    insight: "Specialization in a niche, such as climate quantification for credit, enables rapid establishment as a global leader in that space.",
    labels: ["Climate Tech", "Quantification", "Finance"]
  },
  {
    src: img6,
    name: "SEVEN",
    status: "Active",
    shortDescription: "Behavioral health platform.",
    improvement: "Professional management needs maximum automation to prevent excessive time consumption.",
    insight: "Many professionals recognize the potential to offer improved services and are eager to share their expertise for greater impact.",
    labels: ["Healthtech", "Behavioral Health", "Professional Services"]
  },
  {
    src: img7,
    name: "Viral Blitz",
    status: "Active",
    shortDescription: "Crowdsourced advertising platform.",
    improvement: "Coordinating with numerous third parties can become a complex juggling act. Establishing a strategy with specific deadlines and contingency plans is essential.",
    insight: "Even seemingly straightforward missions often face significant challenges. If it hasn’t been done before, it may be more complex than it appears. Building an MVP may be easy, but scaling the full solution is not, which can make MVP validation insufficient.",
    labels: ["Advertising", "Crowdsourcing", "Social Media"]
  },
  {
    src: img8,
    name: "Caplogiq",
    status: "Active",
    shortDescription: "Hockey contract data insights for teams.",
    improvement: "The line between hobby and business can be blurred. Even hobby projects should lean toward a business structure to ensure consistent financing and growth.",
    insight: "The passion people have for subjects like professional sports is astounding, often outweighing global concerns. People have visceral connections to their sports, entertainment, and media.",
    labels: ["Sports Analytics", "Contract Data", "Fan Engagement"]
  },
  {
    src: img9,
    name: "SymptoTalk",
    status: "Pivot",
    shortDescription: "Patient pre-assessment platform for doctors.",
    improvement: "Understand early on who is positioned to pay for the service. Misaligned incentives can arise if it’s unclear whether clinics, hospitals, insurers, or governments are the primary stakeholders.",
    insight: "Medical software faces unique challenges, especially in areas with poorly aligned incentives. Address incentive alignment early on.",
    labels: ["Healthcare", "Pre-Assessment", "Patient Engagement"]
  },
  {
    src: img10,
    name: "CognifyFX",
    status: "Active",
    shortDescription: "Knowledge enhancement for high-demand, low-supply professionals.",
    improvement: "Focus intensely on a well-defined niche market. Avoid straying toward quick wins that detract from your core purpose.",
    insight: "Vertical AI holds immense potential, but legitimacy will be crucial for adoption. Building credibility in specific sectors will be paramount to success in this space.",
    labels: ["Knowledge Enhancement", "AI", "Professional Development"]
  }
];


export function Project() {
  return (
    <Container id="project">
      <h2>My Businesses</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <ScrollAnimation key={index} animateIn="flipInX">
            <div className="project">
              <header>
                <img src={project.src} alt={`${project.name} logo`} className="project-image" />
                <span className={`status-badge ${project.status.toLowerCase()}`}>
                  {project.status}
                </span>
              </header>

              <div className="body">
                <h3>{project.name}</h3>
                <p className="short-description">{project.shortDescription}</p>

                <div className="details">
                  <p className="improvement-title">Potential for Improvement:</p>
                  <p className="improvement">{project.improvement}</p>
                  <p className="insight-title">Key Insight:</p>
                  <p className="insight">{project.insight}</p>
                </div>
              </div>

              <footer>
                <ul className="tech-list">
                  {project.labels.map((label, i) => (
                    <li key={i}>{label}</li>
                  ))}
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
