// File: src/components/Project/styles.ts

import styled from "styled-components";


export const Container = styled.section`
  margin-top: 15rem;
  
  h2{
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
  }
  .projects{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;
    overflow: hidden;

    .project{
      padding: 2rem 1.8rem;
      background-color: #2b2b2b;
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #FFF;
      position: relative;
      &:hover{
        transform: translateY(-5px);
        background-color: var(--pink);
      }

      header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 3.6rem;
        .project-links{
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        a > img {
          width: 5.0rem;
        }
      }

      .project-image {
        width: 50px; /* Size image to fit well in the corner */
        height: 50px;
        border-radius: 50%;
        margin-right: 1rem;
      }

      .status-badge {
        font-size: 1.5rem;
        font-weight: bold;
        // margin-right: 1rem;
        // margin-top: 3rem;
        margin-left: auto;
        color: #FFF;
        padding: 0.3rem 0.6rem;
        border-radius: 0.4rem;
        text-transform: uppercase;
        // position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }

      .status-badge.failed {
        background-color: rgba(255, 0, 0, 0.8); /* Red for Failed */
      }

      .status-badge.exited {
        background-color: rgba(0, 122, 255, 0.8); /* Blue for Exited */
      }

      .status-badge.active {
        background-color: rgba(0, 128, 0, 0.8); /* Green for Active */
      }

      .status-badge.pivot {
        background-color: rgba(255, 223, 0, 0.8); /* Yellow for Pivot */
      }

      .short-description {
        // font-size: 1.6rem;
        // font-style: italic;
        // color: #ddd;
        margin-bottom: 1.2rem;
      }

      /* Hide improvement and insight sections by default */
      .details {
        display: none;
        // font-size: 1.4rem;
        // color: #bbb;
        margin-top: 1.5rem;
      }

      /* Show details on hover */
      &:hover .details {
        display: block;
      }

      .improvement-title, .insight-title {
        font-weight: bold;
        // color: #ffa726; /* Orange for section titles */
        // color: var(--green);
        margin-top: 1rem;
      }

      .improvement, .insight {
        margin-bottom: 1rem;
        line-height: 1.5;
      }

      .body {
        margin-top: 1rem;
      }
      
      h3{
        margin-bottom: 2rem;
      }

      p{
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        a{
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover{
            color: var(--green);
          }
        }
      }

      footer{
        margin-top: auto;
        .tech-list{
          display: flex;
          align-items: center;
          gap: 2rem;
          font-size: 1.4rem;
          opacity: 0.6;
        }
      }

    }
  }

  @media (max-width: 960px){
    .projects{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 740px){
    .projects{
      grid-template-columns: 1fr;
    }
  }
`